$catalog-filters-bg: #F8F8F8;
$catalog-filters-active-bg: $green;

@-webkit-keyframes indeterminate-bar {
  0% {
    -webkit-transform: translate(-50%) scaleX(0);
  }
  50% {
    -webkit-transform: translate(0%) scaleX(0.3);
  }
  100% {
    -webkit-transform: translate(50%) scaleX(0);
  }
}

@keyframes indeterminate-bar {
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
}

#layout-options, #toggle-filters-button {
  margin-bottom: 5px;
}

.searched-for {
  font-size: 24px;
}

.filter-bullets {
  margin-top: 10px;
}

.current-filters {
  margin-right:5px;
}

.remove-filter {
  margin-right: 5px;
  margin-bottom: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.filters {
  margin: 10px 0 10px 0;
  padding-top: 10px;
  padding-bottom: 20px;
  display: none;
  border: 1px solid #ccc;
  border-right: none;
  border-left: none;
  position:relative;

  .filter-tag {

    .scroll-view {

      @media screen and (min-width: $screen-sm) {
        height: 300px;

        &.small-filter {
          height: 130px;
        }
      }

      > ul {
        li > a {
          text-transform: capitalize;
          display: block;
          padding: 4px 10px;
          color: $dark-gray;
          background: $catalog-filters-bg;
          margin: 2px 0;

          &:hover {
            background: darken($catalog-filters-active-bg, 5%) !important;
            color:#fff;
            text-decoration: none;
          }
        }

        li.active > a {
          background: $catalog-filters-active-bg;
          color:#fff;
        }
      }
    }

    .filter-sublist {
      > li > a {
        padding-left: 12px;
      }

      > li.active > .toggle-subfilter {
        color: white;
      }

      > li > .toggle-subfilter {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 2;
        display: block;
        width: 16px;
        height: 16px;
        text-align: center;
        .fa-plus{
          display:inline-block;
        }
        .fa-minus{
          display:none;
        }
      }

      > li.open > .toggle-subfilter {
        .fa-plus{
          display:none;
        }
        .fa-minus{
          display:inline-block;
        }
      }

      > li > .toggle-subfilter + a {
        padding-left: 24px;
      }

      > li > a {
        font-weight: bold;
        text-transform: uppercase;

        > li {
          text-transform: capitalize;
        }
      }

      > li > ul {
        display: none;
      }

      > li.open > ul {
        display: block;
      }
    }

  }

  .filter-name {
    color: $dark-gray;
    display: block;
    font-weight: normal;
    text-transform: capitalize;
    font-size: 16px;
    padding-left: 10px;
  }
}

#catalog-container {
  position: relative;

  .catalog-spinner {
    display: none;
    z-index: 5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;

    &:after{
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation: indeterminate-bar 2s linear infinite;
        animation: indeterminate-bar 2s linear infinite;
        width:100%;
        height:2px;
        background-color: $green;
        content: '';
        display: block;
    }
  }

  &.catalog-loading {
    .catalog-spinner {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 4;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(white, 0.5);
    }
  }

  .pagination {
    margin: 0;
  }

  .sort-options {
    position: relative;
    margin-right: 5px;

    ul {
       padding: 0;
       position: absolute;
       right: 0;
       top: 32px;
       width: auto;
       display: none;
       z-index: 5;

       li {
         display: block;
       }
    }

    &:hover, &:focus, &.show-submenu {
      ul {
        display: block;
      }
    }

    .sort-dropdown {
      @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));

      > li > a {
        text-align: left;
        padding: 5px 15px 5px 10px;
        border-top-color: transparent;
        font-weight: normal;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.catalog-layout{
  margin-top:10px;
}

.catalog-product {

  .product-thumbnail {
    // hack to have the height as the width
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    img + img{
      display:none;
    }

    &:hover{
      img{
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
        }
      }

      img + img {
        display: block;
      }

    }
  }

  .sticker {
    background-color: white;
    font-size: 13px;
    line-height: 22px;
    padding: 2px;
    position: absolute;
    right: -75px;
    text-align: center;
    text-transform: capitalize;
    top: 14px;
    transform: rotateZ(45deg) translate3d(0, 0, 1px);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 200px;

    &.sticker-76 {
      // yellow
      background-color: #DFCE1E;
      color: white;
    }
    &.sticker-82 {
      // orange
      background-color: #FF911E;
      color: white;
    }
    &.sticker-73 {
      // red
      background-color: #EB3645;
      color: white;
    }
    &.sticker-38 {
      // light blue
      background-color: #3DA9F4;
      color: white;
    }
    &.sticker-75 {
      // blue
      background-color: #2B6D9D;
      color: white;
    }
    &.sticker-187 {
      // green
      background-color: #8BC53D;
      color: white;
    }
    &.sticker-154 {
      // darkgreen
      background-color: #019245;
      color: white;
    }
    &.sticker-227 {
      // pink
      background-color: #EB2095;
      color: white;
    }
    &.sticker-150 {
      // purple
      background-color: #662C92;
      color: white;
    }
    &.sticker-102 {
      // brown
      background-color: #C69C6C;
      color: white;
    }
  }

  .product-id {
    @include reset-a;
    padding: 5px 20px 0 20px;
    @media screen and (max-width: $screen-xs-max) {
      padding: 5px 8px 0 8px;
    }

    > a > div {
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .product-brand {
      font-weight: bold;
      font-size: 14px;
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 0;
      max-width: 90%;
    }

    .product-name {
      text-transform: capitalize;
      color: lighten($gray, 30%);
      padding-right: 5px;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 0;
      max-width: 90%;
    }

    .product-sku {
      text-transform: capitalize;
      color: lighten($gray, 30%);
      padding-right: 5px;
      font-size: 9px;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }

    .product-category {
      color: lighten($gray, 30%);
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }
  }

  .simple {
    margin-bottom: 40px;
    .product-id {
      border-top: 1px solid lighten($gray, 30%);
      margin-top: 1px;
    }
    .prices {
      padding: 0px 0px 0px 20px;
      text-align: left;
      @media screen and (max-width: $screen-xs-max) {
        padding: 0;
       }

      .price {
        width: 100%;
        @media screen and (max-width: $screen-xs-max) {
          margin-left: 8px;
          &.hide-price {
            margin-left: 0;
          }
         }
      }
    }
  }

  .detail {
    display: none;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-gray;

    .product-id {
      padding-left: 0px;
      padding-right: 0px;

      font-size: 20px;

      .product-brand {
        font-size: 26px;
      }
    }

    .prices {
      margin-top: 15px;
      margin-bottom: 15px;
      .price {
        @media screen and (min-width: $screen-md-min) {
          font-size: 26px;
        }
      }
    }
  }
}

.catalog-head {
  display: block;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid $light-gray;
}

.catalog-tail {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: $screen-xs-max) {
  #catalog-container {
    #layout-options {
      text-align: center;
    }

    .paginator {
      float: none;
      text-align: center;
      margin-top: 5px;
    }
  }
}

.catalog-grid {
  .catalog-product {
    .simple {
      display: block;
    }

    .detail {
      display: none;
    }

    .old-price.linedthrough {
      display: none;
    }

  }
}

.catalog-grid .sale-sticker-rounded{
display:none;
}


.catalog-list .sale-sticker-rounded{
  display: inline-block;
  background-repeat: no-repeat;
  width: 68px;
  height: 68px;
  position: absolute;
  background-size: 100%;
}



.sale-sticker{
  display: block !important;
  background-position-x: center;
  width: 65px;
  text-align: center;
  position: absolute;
  right: 15px;
  margin-top: 20px;
  background-repeat: no-repeat;
  height: 80px;
  background-size: 100%;
  z-index: 3;
}

.catalog-list {
  .catalog-product {
    .simple {
      display: none;
    }

    .detail {
      display: block;
    }
  }
}

a > .catalog-checkbox {
  color: #ccc;
  &:hover {
    color: white;
  }
}


.list-tag > li > a {
    line-height: 10px;
    font-size: 12px;
    letter-spacing: 0px;
}



.sizes {
    margin-top: 20px;
}

.sizes > tbody,
.sizes > tbody td,
.sizes > tbody td tr,
.sizes > thead > tr > th {text-align:center!important;}

.sizes > thead > tr > th {
padding-bottom: 15px;
}


.sizes > tbody > tr:nth-child(odd){
  background:#f7f7f7;

}

.sizes > tbody > tr td{
  border:none;

}

.margin-left-6 {
  margin-left: 6px;
}

.flex {
  display: flex;

  &.align-baseline {
    align-items: baseline;
  }
}