.cart-summary {
	hr + hr {
		display: none;
  }
  .total-pay {
    font-weight: 700;
  }
  
  .small-note {
    color: lighten($gray, 30%);
    font-size: 12px;
  }
}

.cart-details {

  .product {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $light-gray;
  }

  .product-thumbnail {
    display: block;
    text-align: center;

    img {
      width: 100%;
      max-width: 320px;
    }
  }

  .table {
    > thead > tr > th {
      border-bottom: 1px solid $table-border-color;
    }
    > tbody > tr > td{
      vertical-align: middle;
    }
    margin-bottom: 0;
  }

  .product-id {
    color: #000;
    text-decoration: none;
    
    .product-brand {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      padding-right: 5px;
    }

    .product-name {
      text-transform: capitalize;
      color: lighten($gray, 30%);
      padding-right: 5px;
      font-weight: normal;
    }
    
    .product-sku {
      text-transform: capitalize;
      color: lighten($gray, 30%);
      padding-right: 5px;
      font-size: 9px;
      font-weight: normal;
    }
  }

  .product-price-label {
    color: lighten($gray, 30%);
    font-size: 9px;
    text-transform: uppercase;
  }

  .product-price, .product-final-price {
    font-size: 24px;
  }

  .btn-cart-remove {
    margin-left: 4px;
    display: inline-block;
  }

}

#cart-selector-container {
  display: flex;
  margin-top: 16px;
  border-bottom: 1px solid #e7e7e7;
  overflow: auto;
  -ms-overflow-style: none;
	scrollbar-width: none;
}

#cart-selector-container::-webkit-scrollbar{
  display: none;
}

.cart-selector {
  padding: 16px;
  border-bottom: none;
  margin-right: 16px;
  background: white;
  color: $black;
  border: none;
  border-bottom: 3px solid transparent;

  *{
    margin: 0 !important;
    white-space: nowrap;
  }
  
  &.selected {
    background: transparent;
    border-color: white;
    color: #008f3f;
    border-bottom: 3px solid #008f3f;
    font-weight: 600;
  }
}

.messages-container {
  align-items: center;
  background-color: $state-success-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  color: $green;
  margin-bottom: 16px;
  border: 1px solid $alert-success-border;
  .single-message {
    margin: 8px 0;
    font-weight: 600;
  }
}