
// We use this to create the icon with three lines aka the hamburger icon, the menu-icon or the navicon
// $width - Width of hamburger icon in rem
// $left - If false, icon will be centered horizontally || explicitly set value in rem
// $top - If false, icon will be centered vertically || explicitly set value in rem
// $thickness - thickness of lines in hamburger icon, set value in px
// $gap - spacing between the lines in hamburger icon, set value in px
// $color - icon color
// $hover-color - icon color during hover
// $offcanvas - Set to true of @include in offcanvas
@mixin hamburger($width, $left, $top, $thickness, $gap, $color, $hover-color, $offcanvas) {
  span::after {
    content: "";
    position: absolute;
    display: block;
    height: 0;

    @if $offcanvas {
      @if $top {
        top: $top;
      }
      @else {
        top: 50%;
        margin-top: -$width/2;
      }
      @if $left {
        left: $left;
      }
      @else {
        left: ($tabbar-menu-icon-width - $width)/2;
      }
    }
    @else {
      top: 50%;
      margin-top: -($width/2);
    }

    box-shadow:
    0 0px 0 $thickness $color,
    0 $gap + $thickness 0 $thickness $color,
    0 (2 * $gap + 2*$thickness) 0 $thickness $color;
    width: $width;
  }
  span:hover:after {
    box-shadow:
    0 0px 0 $thickness $hover-color,
    0 $gap + $thickness 0 $thickness $hover-color,
    0 (2 * $gap + 2*$thickness) 0 $thickness $hover-color;
  }
}

// We use this to add a glowing effect to block elements
// $selector - Used for selector state. Default: focus, Options: hover, active, visited
// $fade-time - Default: 300ms
// $glowing-effect-color - Default: fade-out($primary-color, .25)
@mixin block-glowing-effect($selector:focus, $fade-time:300ms, $glowing-effect-color:fade-out($primary-color, .25)) {
  transition: box-shadow $fade-time, border-color $fade-time ease-in-out;

  &:#{$selector} {
    box-shadow: 0 0 5px $glowing-effect-color;
    border-color: $glowing-effect-color;
  }
}

@mixin reset-a($color: $text-color) {
  a {
    color: $color;

    &:hover, &:focus {
      color: $color;
      text-decoration: none;
    }
  }
}

@mixin no-outline() {
  &:focus {
    outline-color: transparent !important;
    outline-style: none !important;
  }
}

@mixin arrow-down($size: 5px) {
  content: '';
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $black;
}

@mixin inline-table() {
  padding: 0;
  list-style: none;
  /* legacy flexbox */
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -ms-flexbox;
  /* new flexbox */
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;

  width: 100%;

  .no-flexbox.no-flexboxlegacy & {
    width: 100%;
    display: table;
  }

  > li {
    margin: 0;
    padding: 0;
    display: block;

    /* legacy flexbox */
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    /* new flexbox */
    -webkit-flex:1 auto;
    -moz-flex:1 auto;
    -ms-flex:1 auto;
    flex:1 auto;

    .no-flexbox.no-flexboxlegacy & {
      display: table-cell;
    }
  }
}

@mixin user-select-and-callout($select: none) {
  -webkit-touch-callout: $select;
  -webkit-user-select: $select;
  -khtml-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
  user-select: $select;
}

@mixin rotate($angle: 90deg) {
  -ms-transform: rotate($angle); /* IE 9 */
  -webkit-transform: rotate($angle); /* Chrome, Safari, Opera */
  transform: rotate($angle);
}

@mixin transition($params...) {
  -webkit-transition: $params;
  -moz-transition: $params;
  -o-transition: $params;
  transition: $params;
}
