.breadcrumb {
  ul {
    padding: 5px 0px 5px 10px;
    margin: 0px;
    list-style: outside none none;

    > li {
      display: inline-block;
      padding-right: 5px;

      > a {
        color: $dark-gray;
        text-transform: capitalize;
      }
    }
  }
}