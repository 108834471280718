@import 'font-awesome/font-awesome';

.fa-spacing{
  margin-right: 8px;
}

.fa-spacing-before{
  margin-left: 8px;
}

.fa-transition{
  -webkit-transition: transform 400ms linear, -webkit-transform 400ms linear;
  -moz-transition: transform 400ms linear, -moz-transform 400ms linear;
  -ms-transition: transform 400ms linear, -ms-transform 400ms linear;
  -o-transition: transform 400ms linear, -o-transform 400ms linear;
  transition: transform 400ms linear;
}

.#{$fa-css-prefix}-rotate-90x  { @include fa-icon-rotate(-90deg, 1);  }

:root .#{$fa-css-prefix}-rotate-90x{
  filter: none;
}

.#{$fa-css-prefix}-rotate-180x  { @include fa-icon-rotate(-180deg, 1);  }

:root .#{$fa-css-prefix}-rotate-180x{
  filter: none;
}

.fa-blogger-svg {
  width: 16px;
  background-size: 16px 16px;
  background-position: center center;
  background-repeat: no-repeat;
  height: 16px;
  margin-top: 8px;
  background-image: url(../images/social-fa-blogger.svg);
}

.fa-fw-r {
  width: 12px;
}
