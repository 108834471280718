@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/ProximaNova-Regular.eot?') format('eot'),url('../fonts/ProximaNova-Regular.woff2') format('woff2')
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/ProximaNova-Semibold.eot?') format('eot'),url('../fonts/ProximaNova-Semibold.woff2') format('woff2')
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: lighter;
  src: url('../fonts/ProximaNova-Light.eot?') format('eot'), url('../fonts/ProximaNova-Light.woff2') format('woff2')
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/ProximaNova-Bold.eot?') format('eot'),url('../fonts/ProximaNova-Bold.woff2') format('woff2')
}
 