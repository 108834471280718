
.bottom-line {
  border-bottom: 1px solid $footer-line-color;
  padding-bottom: 10px;
}

.btn-brands {
  background-color: $green;
  border-color: darken($green, 5%);
  color: white;
  @include no-outline;

  &:hover, &:focus {
    background: darken($green, 5%);
    border-color: darken($green, 10%);
    color: white;
  }
}

.btn-brands-light {
  background-color: $lightgreen;
  border-color: darken($lightgreen, 5%);
  color: white;
  @include no-outline;

  &:hover, &:focus {
    background: darken($lightgreen, 5%);
    border-color: darken($lightgreen, 10%);
    color: white;
  }
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.small-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.small-margin {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.padding-10 {
  padding: 10px;
}

hr, .hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: $light-gray;
}

.striped {
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  position: relative;

  &:before {
    background-color: $light-gray;
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 30px;
    width: 100%;
  }

  span {
    background-color: white;
    display: inline-block;
    padding: 0 10px;
    position: relative;
  }
}

.line-through {
  text-decoration: line-through;
}

.bg-gray{
  background-color: $light-gray;
}

.visible-xs-inline {
  @media screen and (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
  display: none;
}

.hidden-xxs {
  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

body.anonymous .hidden-anonymous{
  display: none !important;
}

body.logged .hidden-logged{
  display: none !important;
}
body.logged .hidden-drop{
  display: none !important;
}
body.logged .hidden-nodrop{
  display: none !important;
}
.text-wrap {
  white-space: normal;
  word-wrap: normal;
  word-break: normal;
}

.form-group-subscribe {
  margin-bottom: 10px;

  label {
    margin-bottom: 0;
  }

  .inline-checkbox {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}
