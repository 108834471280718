#profile-container {
  margin-top: 20px;

  .save-button {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  #profile-nav {
    > li {
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom: 2px solid $green;
        font-weight: bold;

        > a {
          color: $green;
          background-color: transparent;

          &:hover {
            background-color: transparent;
            color: darken($green, 20%);
          }
        }
      }

      > a {
        border-radius: 0;
        color: $text-color;
        text-transform: capitalize;

        &:hover {
          background-color: transparent;
          color: darken($green, 20%);
        }
      }
    }
  }

  .paginator {
    text-align: center;
  }

  .roles {
    .role {
      text-transform: capitalize;
    }
  }
}

.order-inner-link {
  > a {
    display: block;
  }
}

.order-row {
  cursor: pointer;
}

.order-controls {
  display: none;

  &:hover {
    background-color: transparent !important;
  }

  .order-controls-row {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      line-height: 33px; // button height
      text-transform: capitalize;
    }
  }

  .options-buttons .btn {
    @media screen and (max-width: $screen-sm-max) {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .order-controls-return {
    > thead > tr > th {
      text-transform: capitalize;
    }
    > tbody > tr > td {
      vertical-align: middle;
    }

    .return-request-active {
      td:not([rowspan]) {
        background-color: #f5f5f5;
      }
    }
  }
}

.table > tbody > tr.order-controls {
  border-left: 3px solid $green;
  > td {
    padding-left: 18px;
  }
}

.new-return-request{
  display: none;
}

.row-returned-goods {
  dl {
    margin-bottom: 0;
  }

  .cancel-request {
    @media screen and (min-width: $screen-sm-min) {
      margin-top: 42px;
    }
    margin-top: 15px;
  }
}

.input-validated {
  color: $green;
  text-transform: capitalize;

    span {
      margin: 0 2px 0 7px;
    }
}

.profile-api-key {
  font-size: 16px;
  padding-left: 5px;
  color: gray;
}
.profile-privacy-subtitle {
  font-size: 14px;
  padding: 5px 27px 14px;
  margin: 0;
  @media screen and (max-width: $screen-md-max) {
    padding: 5px 0 15px;
  }
}

.profile-privacy-row {
  border-top: 1px solid $footer-line-color;
  border-bottom: 1px solid $footer-line-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  margin-bottom: -1px;
  @media screen and (max-width: $screen-xs-max) {
    flex-direction: column;
    padding: 14px 0 8px;
  }

    .profile-privacy-col-img {
      width: 16%;
      text-align: center;
      @media screen and (max-width: $screen-md-max) {
        width: 12%;
        text-align: left;
      }
      @media screen and (max-width: $screen-xs-max) {
        display: none;
      }

        img {
          width: 75px;
        }
    }

    .profile-privacy-col-title {
      width: 27%;
      @media screen and (max-width: $screen-sm-max) {
        width: 23%;
      }
      @media screen and (max-width: $screen-xs-max) {
        width: 100%;
      }

        h4 {
          margin: 0;
          font-weight: 700;
          text-transform: uppercase;
          max-width: 200px;
          @media screen and (max-width: $screen-xs-max) {
            max-width: none;
            margin: 5px 0;
          }
        }
    }

    .profile-privacy-col-descr {
      width: 41%;
      @media screen and (max-width: $screen-md-max) {
        width: 43%;
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 45%;
      }
      @media screen and (max-width: $screen-xs-max) {
        width: 100%;
      }

        p {
          margin: 0;
          padding-right: 55px;
          @media screen and (max-width: $screen-md-max) {
            padding-right: 40px;
          }
        }
    }

    .profile-privacy-col-inputs {
      width: 16%;
      @media screen and (max-width: $screen-md-max) {
        width: 18%;
      }
      @media screen and (max-width: $screen-sm-max) {
        width: 20%;
      }
      @media screen and (max-width: $screen-xs-max) {
        width: 100%;
      }
    }

    .inline-checkbox {
      display: block;
      font-size: 16px;
      margin: 10px 0;

        label {
          padding-left: 20px;
        }

        input[type=checkbox]+label:before {
          height: 21px;
          width: 21px;
          border-color: $dark-gray;
        }
    }
}

.profile-privacy-footer {
  text-align: center;
  margin: 20px 0;
  @media screen and (max-width: $screen-xs-max) {
    margin: 20px 0 60px;
  }

    img {
      width: 25px;
    }

    p {
      margin: 15px 0;
    }

    .btn-brands {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.03em;
      padding: 7px 50px;
      background-color: #333333;
      border-color: #333333;
      transition: all .15s ease-out;

        &:hover {
          background-color: $black;
          border-color: $black;
        }
    }
}

.wallet-item {
  box-shadow: 0 0 20px rgba(150, 150, 150, 0.25);
  border-radius: 5px;
  padding: 23px;
  text-align: center;
  margin: 20px 0 10px;

    span {
      color: #008f3f;
    }

    h3 {
      margin: 3px 0;
      font-size: 20px;
    }

    p {
      margin: 12px 0 18px;
    }
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.btn-refresh {
  background: none;
  border: none;
  padding: 0;
  color: #008f3f;
  margin: 10px 0 50px;
}

.exit-button {
  border: none;
  background: transparent;
  padding: 10px 15px;
}