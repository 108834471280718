.subscription-cards-container {
  height: 600px;
  position: relative;
  margin-top: 15px;
  @media screen and (max-width: $screen-sm - 1) {
    height: auto;
    margin-bottom: 40px;
  }
}

.subscription-title {
  padding: 30px;
  @media screen and (max-width: $screen-sm - 1) {
    font-size: 26px;
    padding: 10px;
  }
}

.subscription-card-header {
  padding: 20px 10px;

    h2 {
      text-transform: uppercase;
    }
}

.subscription-card-footer {
  background: rgba(137, 193, 123, 0.1);
  border-top: solid 1px #cbcbcb;
  padding: 25px 10px 70px;
  font-size: 16px;
  color: #666;
  line-height: 26px;

    li {
      white-space: normal;
    }
}

.subscription-header-price {
  color: #89c17b;

    .price-currency {
      position: relative;
      top: -21px;
      left: -3px;
      font-weight: bold;
    }

    .price-value {
      font-size: 48px;
    }
}

.subscription-description {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @media screen and (max-width: $screen-sm - 1) {
    position: static;
  }
}

.subscription-card {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);
  border-radius: 6px;
  background: white;
}

.subscription-select {
  border: none;
  box-shadow: none;
  width: auto;
  font-size: 18px;
  font-weight: bold;
  color: #a4a4a4;
  position: relative;
  left: -7px;
  top: -6px;
  font-weight: 500;

    &:focus {
      box-shadow: none;
    }
}

.btn-subscription {
  background: #89c17b;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  border-radius: 3px;
  padding: 12px 30px;
  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}

.subscription-paypal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

    img {
      width: 100px;
      margin-right: 10px;
    }

    p {
      margin: 5px 0 0;
      font-size: 12px;
    }
}

.subscription-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 15px;
  @media screen and (max-width: $screen-md-max) {
    flex-direction: column;
    align-items: normal;
  }
  @media screen and (max-width: $screen-sm-min) {
    flex-direction: initial;
    align-items: center;
  }
  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
    align-items: normal;
  }
}

.subscription-form-prices {
  font-size: 20px;
  @media screen and (max-width: $screen-md-max) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: $screen-sm-min) {
    margin-bottom: 0;
  }
  @media screen and (max-width: $screen-xs) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.subscription-pay-info {
  padding-right: 20px;
  @media screen and (max-width: $screen-md-max) {
    padding-right: 0;
  }
}

.subscription-ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

    span {
      font-size: 10px;
      font-weight: bold;
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 100px;
      display: block;
      background: #9BC90D;
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 19px;
      right: -21px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 100%;
          z-index: -1;
          border-left: 3px solid #79A70A;
          border-right: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-top: 3px solid #79A70A;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 100%;
          z-index: -1;
          border-left: 3px solid transparent;
          border-right: 3px solid #79A70A;
          border-bottom: 3px solid transparent;
          border-top: 3px solid #79A70A;
        }
    }
}



// subscription archive
.subscription-archive-title {
  font-size: 30px;
  margin: 30px 0 10px;
}

.subscription-archive-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #c9c9c9;
  @media screen and (max-width: $screen-sm-min) {
    flex-direction: column;
    padding: 15px 0;
  }

    span {
      width: 85%;
      font-size: 16px;
      @media screen and (max-width: $screen-sm-min) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    a {
      width: 15%;
      @media screen and (max-width: $screen-sm-min) {
        width: 100%;
      }
    }
}



// subscription profile
.table-subscription {

    >tbody>tr>td {
      vertical-align: middle;
      padding: 4px;
    }
}

.btn-table {
  width: 100%;

    &.btn-table-disabled {
      background: none;
      color: #c7c7c7;
      border-color: #c7c7c7;
    }

    &.btn-table-empty {
      background: none;
      color: #047535;
    }
}

table>tbody>tr>td.subscriptions-control {
  padding: 20px 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.order-subscription-table {
  padding: 0 5px 0 20px;
  @media only screen and (max-width: $screen-xs) {
    padding: 0 5px 0 7px;
  }

    h4 {
      white-space: normal;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      display: flex;
      padding: 10px 10px;
      @media only screen and (max-width: $screen-xs) {
        flex-direction: column;
      }

        &:nth-child(odd) {
          background-color: whitesmoke;
        }

        > span {
          flex: 1;

          &:first-child {
            font-weight: bold;
          }
        }
    }
}