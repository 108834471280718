@import 'settings';
@import 'mixins';
@import 'fonts';
@import 'font-awesome';
@import 'bootstrap';
@import 'bootstrap-override';
@import 'bootstrap-addon';
@import 'nanoscroller';

@each $lang in it, en, de, es, fr, ro {
  .lang-#{$lang} {
    width: 24px;
    height: 18px;
    // workaround
    background-image: url(../images/flags/#{$lang}.svg);
    background-size: 24px 18px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    vertical-align: top;

    @include box-shadow(1px 1px 1px #c9c9c9);
  }
}

@each $i in 5, 10, 15, 20, 30, 40, 50, 60, 80, 100, 120 {
  .padding-#{$i} {
    padding: #{$i}px;
  }
  @each $dir in top, bottom, left, right {
    .margin-#{$i}-#{$dir} {
      margin-#{$dir}: #{$i}px;
    }
    .padding-#{$i}-#{$dir} {
      padding-#{$dir}: #{$i}px;
    }
  }
}

#login-container {
  @include reset-a($text-color);
}

.i-number {
  display: inline-block;
  width: 60px;
  border-left: none;
  border-right: none;
  text-align: center;
  vertical-align: middle;
}

.i-number-minus, .i-number-plus {
  @include no-outline;
  @extend .btn-default;
}

.add-to-cart-button {
  margin-bottom: 5px;
  width: 100%;
}

.not-found-message {
  margin: 40px 0;
}

#cookie-law {
  z-index: 2147483648;
  position: fixed;
  bottom: 0px;
  background-color: $black;
  width: 100%;

  p {
    text-align: center;
    margin: 10px;
    color: white;

    a {
      cursor: pointer;
    }
  }
}

.subscribe-title {
  color: $green;
  font-weight: bold;
  text-transform: uppercase;
}

.subscribe-subtitle {
  text-transform: uppercase;
}

.text-gray {
  color: $gray !important;
}

.text-newgreen{
  color: #7db923;
}

.step-login-text {
    line-height: 14px;
    font-size: 13px;
}



.step-login-text.off-state,
.step-login-text.second-step.off-state,
.step-login-text.second-step.off-state .text-newgreen,
.step-login-text.off-state .text-newgreen{
color: #d6d6d6;
}

.step-login-text.second-step{
  color:#333333;
}

.title-forgot-div {
    display: inline-block;
    position: relative;
    width: 100%;
}


/* POPUP SIZE GUIDE */

.show-size-guide{
  cursor:pointer;
}

.popup-overlay-container {
    display:none;
    background: rgba(90, 90, 90, 0.8);
    position: fixed;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 99999;
}

.popup-size-container {
  display:none;
}


/* NEW DESCRIPTION STYLE */

.pdbDescSection {
	  display: block;
    line-height: 19px;
		}
.pdbDescSectionTitle {
		font-weight: bold;
    display: inline-block;
    padding-right: 5px;
    color: #707070;
    font-size: 13px;
		}
.pdbDescSectionText {
		color: #8f8f8f;
    font-size: 13px;
		}

.pdbDescSectionItem:not(:last-child):after {
  content: ", ";
}


@import 'components/breadcrumb';
@import 'components/prices';
@import 'components/header';
@import 'components/megamenu';
@import 'components/footer';
@import 'components/profile';
@import 'components/product';
@import 'components/catalog';
@import 'components/cart';
@import 'components/designers';
@import 'components/dialog';
@import 'components/checkout';
@import 'components/hovereffects';
@import 'components/subscribe';
@import 'components/subscriptions';
@import 'components/merchants';
