.prices {
  width: 100%;
  text-align: center;

  .price {
    width: 32%;
    font-size: 18px;
    vertical-align: top;
    font-weight: bold;
    display: inline-block;
    line-height: 1em;
    text-transform: uppercase;

    &.hide-price {

      a {
        color: #000;
        position: relative;
        &.wholesale-link {
          font-size: 12px;
          line-height: 16px;
          text-transform: initial;
          display: flex;
          align-items: center;
          @media screen and (max-width: $screen-xs-max) {
            margin-left: 8px;
          }
          span {
            background-color: #CBFE03;
            margin-left: 4px;
            @media screen and (max-width: $screen-xs-max) {
              margin-left: 0;
            }
          }
        }

        &:hover {
          color: #008F3F;
          text-decoration: none;
        }

        span.fa {
          color: #008F3F;
          padding-left: 5px;
        }
      }
    }

    &.old-price {
    color: #c0c0c0;
    font-weight: normal;
}

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      font-size: 15px;
    }
  }

  .suggested-price {
    color: lighten($gray, 30%);
  }

  .retail-price {
    color: lighten($gray, 30%);
    font-weight: normal!important;
    font-size: 22px;
    &.not-logged {
      font-size: 12px;
      line-height: 16px;
      text-transform: initial;
      margin-top: 8px;
      @media screen and (max-width: $screen-xs-max) {
        margin-left: 8px;
        text-align: left;
      }
    }

  }

  .price-label {
    display: block;
    font-size: 10px;
    font-weight: lighter;
    text-transform: uppercase;
    line-height: 10px;
  }

.sub-price-label {
  font-size: 10px;
font-weight: normal;
line-height: 10px;
display: block;
margin-top: 7px;
text-transform: none;
font-style: italic;
}
}


.text-left-adjust {
    text-align: left;
}

.linedthrough{
  text-decoration: line-through;
}


.was-price{
    font-size: 15px!important;
    margin-top: 2px!important;
    color: #959494!important;
}

.iva-adverts {
    display: inline-block;
    float: right;
    padding-bottom: 20px;
    font-size: 12px;
}

.price-mid-important {
    font-size: 26px;
}

.price-important {
    font-size: 28px;
}
