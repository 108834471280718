.list-tag {
  > li {
    padding: 0;
    margin-bottom: 5px;
    > a {
      line-height: 10px;
    }
  }
}

.list-color {
  span {
    display: inline-block;
    margin-right: 4px;
    width: 20px;
    height: 20px;
    text-indent: -20000px;
    margin-top: 19px;
    border-radius: 100%;

    border-width: 2px;
    border-style: solid;

    box-shadow: 0 0 1px #888;
  }
}

#product-container {
  .product-information {
    .prices {
      .price {
        font-size: 20px;
        @media screen and (min-width: $screen-lg-min) {
          font-size: 22px;
        }
      }
    }
  }
}

.sizes {
  font-size: 12px;

  > thead > tr > th {
    border-bottom: 1px solid $light-gray;
    line-height: 1;
    padding: 4px 8px;
    text-transform: uppercase;
    vertical-align: middle;
  }

  > tbody > tr > td {
    border-bottom: 1px solid $light-gray;
    border-top: none;
    line-height: 1;
    padding: 4px 8px;
    vertical-align: middle;
  }

  > tbody > tr.not-available > td {
    line-height: 1.5;
    color: lighten($gray, 30%);
  }
}

#info-container {
  .info-title {
    @include reset-a($text-color);
    width: 100%;
    display: block;
    border-bottom: 1px solid #c9c9c9;

    a {
      display: block;
      width: 100%;
      line-height: 35px;
    }
  }
}

.product-banner {
  margin-top: 30px;
}

.product-banner > div > a {
  cursor: pointer !important;
}

.product-category-related {
  margin-top: 45px;
}

.hidden-price-icon {
  width: 13px;
}

.merchant-tag-label {
  font-size: 12px;
  line-height: 10px;
  padding: 6px 12px;
  border: 1px solid #ccc;
}