.hover-effect-2 {
    overflow: hidden;
    background-color: #000;
    
    img{
      transition: all 400ms;
      transform: scale(1.05);
    }
    
    h5 {
      opacity: 0;
      transition: all 400ms;
      transform: translateY(-10px);
    }
    
    &:hover{
      img{
        opacity: 0.5;
        transform: scale(1);
      }
      h5{
        opacity: 1;
        transform: translateY(0);
      }
    }
}