.megamenu {

  > ul {
    @include inline-table;

    @media screen and (max-width: $screen-xs-max) {
      display: flex;
      flex-direction: column;
    }
  }

  > ul > li.collapse:not(.in) {
    display: none !important;
  }

  > ul > li {
    font-weight: normal;
    text-align: center;
    line-height: 40px;
    text-transform: capitalize;

    a {
      color: $text-color;
      &:hover, &:focus {
        color: $text-color;
      }
      @media screen and (max-width: $screen-xs-max) {
        color: white;
      }
    }

    > a {
      display: block;
      width: 100%;
      position: relative;

      &:hover, &:focus {
        text-decoration: none;
      }

      span.fa {
        display: none;
      }
    }

    &:hover, &.show-submenu {
      /* don't show the submenu on mobile */
      @media screen and (min-width: $screen-sm-min) {
        .submenu {
          display: block;
        }
      }
      
      > a:after{
        content: '';
        border-bottom: 3px solid $green;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        z-index: 200;
      }
    }

    &.stocklot {
      > a {
        color: $green;
        font-weight: bold;
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    &.collapse {
      display: block;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    background-color: $gray;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;

    > ul > li {
      width: 100%;
      text-align: left;
      display: block;
      color: white;
      text-transform: uppercase;
      border-bottom: 1px solid white;
      font-weight: bold;
      padding: 0;

      > a {
        padding-left: 20px;
        color: white;

        &:hover, &:focus {
          color: $green;
        }
        span.fa {
          display: inline-block;
        }
      }

      &:hover {
        border-bottom: 1px solid $green;
      }
    }
  }
}

.sales > a {
  color: #ff1652 !important;
  font-weight: 600;
}

.submenu {
  background-color: white;
  border-top: 1px solid $light-gray;
  display: none;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px + 40px;
  z-index: 6;
  line-height: 24px;

  @include box-shadow(0px 3px 5px rgba(0, 0, 0, .4));

  .section-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
    text-transform: uppercase;

    > a {
      color: inherit;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      text-transform: capitalize;
      line-height: 24px;

      > a {
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .see-all-button {
    font-weight: bold;
    text-transform: uppercase;
  }
}
