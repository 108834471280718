.row-group {
  margin-bottom: 30px;
}

.designer {
  color: $text-color;
  line-height: 32px;
  display: block;
  width: 100%;

  &:hover {
    color: $text-color;
  }
}
