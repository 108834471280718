.header-bar {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding: 0;
            margin: 0 8px;
            @media only screen and (max-width: 340px) {
                margin: 0 3px;
            }
            display: inline-block;
            line-height: 34px;
            vertical-align: top;
            font-size: 13px !important;
            font-weight: normal;
        }
    }

    .user-menu {
        @include reset-a;
        font-weight: bold;
        padding: 0;
        margin: 4px 0 0 0;

        > li > a {
            display: block;
        }

        > li {
            opacity: 1;
            // @include transition(opacity 300ms);
        }

        > li.subscribe {
            background: #8ac037;
            padding: 1px 12px;
            @media only screen and (max-width: 600px) {
                padding: 1px 4px;
            }
            text-transform: uppercase;
            line-height: 32px;
            &:hover {
                background: #91c14d;
            }

            a {
                color: $white !important;
                font-weight: 300;
            }
        }

        &.with-brands-logo {
            .follow-us,
            .subscribed-users {
                display: none;
            }
            .brands-logo {
                opacity: 1;
                visibility: visible;
            }
        }

        .brands-logo {
            opacity: 0;
            // transition: opacity 300ms, visibility 0s 300ms;
            position: absolute;
            left: 15px;
            visibility: hidden;
            img {
                height: 30px;
                vertical-align: middle;
            }
        }

        .social-button {
            font-size: 20px;
            display: inline-block;
            vertical-align: top;
            margin: 0 5px;
        }

        #cart-button {
            position: relative;
            display: flex;
            align-items: center;

            span.fa {
                vertical-align: top;
                font-size: 20px;
                line-height: 34px;
            }
            div.count {
                position: absolute;
                font-size: 12px;
                background-color: #8bbe42;
                color: $white;
                display: block;
                top: 2px;
                right: -16px;
                height: 18px;
                text-align: center;
                border-radius: 43px;
                line-height: 20px;
                padding: 0 12px;
                @include box-shadow(-1px 1px 0 $header-bg-color);
            }

            .cart-name {
                color: #8bbe42;
                margin: 0 8px;
            }
        }

        .logout {
            form {
                padding: 0;
                margin: 0;
            }

            input[type="submit"] {
                @include no-outline;
                background: none;
                border: none;
                margin: 0;
                padding: 0;
                outline: none;
                outline-offset: 0;

                &::-moz-focus-inner {
                    border: none;
                    padding: 0;
                }
            }
        }

        .languages {
            padding: 5px 18px 5px 5px;
            line-height: 24px;

            &:hover,
            &:focus {
                background-color: $white;
            }

            > a {
                display: block;
                position: relative;

                &:after {
                    @include arrow-down(4px);
                    display: block;
                    right: -15px;
                    position: absolute;
                    top: 10px;
                }
            }
            ul {
                padding: 0;
                position: absolute;
                top: 34px;
                width: auto;
                display: none;

                li {
                    display: inline-block;
                }
            }

            &:hover,
            &:focus,
            &.show-submenu {
                ul {
                    display: inline-block;
                    text-align: center;
                }
            }
        }

        .currency {
            padding: 5px 18px 5px 5px;
            line-height: 24px;
            margin-right: 0;
            margin-left: 0;
            position: relative;

            &:hover,
            &:focus {
                background-color: $white;
            }

            > a {
                display: block;
                position: relative;

                &:after {
                    @include arrow-down(4px);
                    display: block;
                    right: -15px;
                    position: absolute;
                    top: 10px;
                }
            }
            ul {
                padding: 0;
                position: absolute;
                top: 34px;
                width: auto;
                display: none;

                li {
                    display: block;
                }
            }

            &:hover,
            &:focus,
            &.show-submenu {
                ul {
                    display: block;
                }
            }
        }

        .profile-dropdown {
            columns: 3;
            @media only screen and (max-width: 340px) {
                columns: 2;
            }
            background: $white;
            margin-left: -10px;
            @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));

            > li {
                background-color: $white;
                line-height: 30px;
                margin: 0;
                padding: 0;

                > a {
                    > span {
                        margin-right: 10px;
                        position: relative;
                        top: 6px;
                    }

                    padding: 5px 15px 5px 10px;
                    font-weight: normal;
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                &:last-child {
                    border-radius: 0 0 3px 3px;
                }
            }
        }

        .lang-dropdown {
            z-index: 1001;
            columns: 3;
            @media only screen and (max-width: 340px) {
                columns: 2;
            }
            background: $white;
            margin-left: -10px;
            @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));

            > li {
                background-color: $white;
                line-height: 30px;
                margin: 0;
                padding: 0;

                > a {
                    > span {
                        margin-right: 10px;
                        position: relative;
                        top: 6px;
                    }

                    padding: 5px 15px 5px 10px;
                    font-weight: normal;
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                &:last-child {
                    border-radius: 0 0 3px 3px;
                }
            }
        }

        .curr-dropdown {
            z-index: 1001;
            margin-left: -10px;
            @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));

            > li {
                background-color: $white;
                line-height: 30px;
                margin: 0;
                padding: 0;
                padding: 5px 15px 5px 10px;
                font-weight: normal;
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;

                &:last-child {
                    border-radius: 0 0 3px 3px;
                }
            }
        }

        .subscribed-users {
            font-weight: bold;

            .user-counter {
                margin-left: 15px;
                display: inline;
                letter-spacing: 5px;
                color: $green;
            }
        }
    }

    .alert-box {
        position: fixed;
        left: 0;
        top: 44px;
        margin-left: 0;
        width: 300px;
        z-index: 100;

        .alert {
            @include box-shadow(0px 0px 3px #ddd);
        }
    }
}

#header-navigation {
    margin: 0 auto;
    position: relative;

    .hamburger {
        @include hamburger(20px, 20px, 3px, 2px, 6px, $white, $white, false);
        @include no-outline;
        border-radius: 3px;
        background-color: #a0a0a0;
        height: 40px;
        margin: 3px 0 0 0;
        padding: 0;
        width: 40px;

        span {
            left: 25px;
            position: absolute;
            bottom: 18px;
        }

        &.selected {
            background-color: #8ac037;
        }
    }

    .logo {
        img {
            max-width: 100%;
        }
    }

    .icon-search {
        position: absolute;
        right: 18px;
        top: 18px;
        padding: 0;
        width: fit-content;
    }

    .search-bar {
        display: inline-block;
        height: 30px;
        width: 260px;
        max-width: 100%;
        vertical-align: middle;
        margin-top: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: $screen-sm - 1) {
            width: 100%;
        }
    }

    .social-icons-col {
        padding-right: 15px;
    }

    .social-icons {
        margin-top: 10px;

        li {
            margin: 0;
            padding: 0;
        }
        img {
            width: 30px;
            height: 30px;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    #header-navigation {
        top: 0;
        z-index: 1000;
        padding-bottom: 6px;
        position: sticky;
        background: $white;

        &.stuck {
            position: static;
            .logo {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    #header-user {
        &.stuck {
            top: 0;
            padding-bottom: 6px;
            position: sticky;
            background: $white;
            border: none;
            border-bottom: 1px solid #eaeaea;
            z-index: 1000;
            .follow-us,
            .subscribed-users {
                display: none;
            }
            .brands-logo {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

#preheader {
    position: relative;
    top: 0px;
    display: block;
    left: 0px;
    background: #9ad743;
    width: 100%;
    color: $white;
    text-align: center;
}

#preheader span.firstphrase {
    margin: 10px 7px 0px !important;
    display: inline-block;
    line-height: 31px;
    letter-spacing: 1px;
    font-size: 19px;
    font-weight: 100;
    color: $white;
}

#preheader span.secondphrase {
    margin: 0px 7px 0px !important;
    display: inline-block;
    line-height: 34px;
    letter-spacing: 1px;
    font-size: 19px;
    font-weight: 100;
    color: $white;
}

.user-menu .help-menu {
    font-size: 13px;
    font-weight: normal;
    &:hover {
        text-decoration: underline;
    }
}

.divider-pipe {
    margin-left: 0 !important;
    margin-right: 0 !important;
    color: #85c40e;
}

.counterbox {
    cursor: pointer;
}

.countdown-container h2 {
    font-size: 45px;
}

.countdown-container {
    bottom: 30px;
    display: inline-block;
    padding: 0px;
    color: $white !important;
    width: 100%;
}

#getting-count {
    height: auto;
}

.time {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    display: inline-block;
    text-align: center;
    position: relative;
    height: 70px;
    width: 48px;
}

.count {
    background: #202020;
    color: #f8f8f8;
    display: block;
    font-family: "Oswald", sans-serif;
    font-size: 1.8em;
    line-height: 70px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    top: 0;
    // width: 100%;
}

.count.top {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    border-radius: 5px 5px 0 0;
    height: 50%;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}

.count.bottom {
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), transparent);
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
    background-image: -ms-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
    background-image: -o-linear-gradient(rgba(255, 255, 255, 0.1), transparent);
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-radius: 0 0 5px 5px;
    line-height: 0;
    height: 50%;
    top: 50%;
}

.time-label {
    font-size: normal;
    margin-top: 5px;
    display: block;
    position: absolute;
    width: 100%;
    color: #000;
    font-size: 1em;
    top: 66px;
}

.profile {
    position: relative;
    .profile-popup {
        position: absolute;
        background: $white;
        padding: 8px 16px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        width: max-content;

        .exit-button {
            padding: 0;
        }
    }
}

.profile:hover {
    @media screen and (min-width: $screen-sm-min) {
        .profile-popup {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1001;
        }
    }
}

.menu-exit-button {
    border: none;
    background: transparent;
    padding: 0;
    text-transform: uppercase;
}
