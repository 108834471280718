.dialog {
  background-color: #fff;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 40px;

  .dialog-title {
    color: #008F3F;
    font-size: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    font-weight: bold;
  }

  .dialog-subtitle {
    font-size:16px;
    font-style: italic;
    color:#333333;
  }
}

.dialog-desclaimer {
  font-size: 12px;
}

.dialog-login {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px 40px;
}

.dialog-login-top {
  margin-top: 50px;
  background-color: #f7f7f7;
  padding: 10px;
}