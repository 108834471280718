#merchant-main-banner {
  .merchant-text-container {
    text-align: center;
    background: #f6f6f6;
    padding: 16px;
    margin-bottom: 30px;
  }
  
  .merchant-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #333;
  }
  
  .merchant-order {
    color: $gray;
    font-size: 12px;
    line-height: 16px;
  }
  
  .merchant-block-cover-image {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .merchant-image {
    width: 100%;
    object-fit: cover;
  }
}


.merchant-block-cover-image {
  margin: 16px 0;
  width: 100%; 
  height: 300px;
  background-repeat: no-repeat; 
  background-size: cover;
  @media (max-width: $screen-md) { 
    height: 200px;
  }
}

.filter-title {
  margin: 0;
  font-size: 16px;
}

.merchant-filters-dropdown {
  a {
    color: $black;
    margin: 8px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  a:hover{
    text-decoration: none !important;
  }
}

#filters-button-mobile {
  margin: 16px;
  background: #8bbe42;
  border: none;
  color: white;
  padding: 8px 16px;
  width: fit-content;
}

.filters-container-mobile{
  @media screen and (max-width: $screen-xs-max) {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    &.open {
      max-height: 50vh;
      transition: all 0.5s ease-in-out;
      overflow: auto;
    }
  }
    
}

.color-green {
  color: $green
}

.merchant {
  opacity: 1;
  margin-bottom: 0 !important;
  &.hidden {
    display: none;
  }
}

.merchant-loading {
  pointer-events: none;
  opacity: 0.7;
  #merchant-main-banner {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}

.merchant-error {
  pointer-events: none;
  opacity: 0.5;
}